import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import { FiThumbsUp, FiCopy } from 'react-icons/fi';
import { FaInstagram, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';
import { Analytics } from "@vercel/analytics/react";
import './App.css';

console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('Supabase Anon Key:', process.env.REACT_APP_SUPABASE_ANON_KEY);

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const meanWords = ["fuck", "shit", "motherfucker", "whore", "motherfucking"];

const containsMeanWords = (text) => {
  return meanWords.some((word) => text.toLowerCase().includes(word));
};

// Time ago function to calculate relative time
const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) return `${interval} year${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) return `${interval} month${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return `${interval} day${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return `${interval} hour${interval === 1 ? '' : 's'} ago`;

  interval = Math.floor(seconds / 60);
  if (interval >= 1) return `${interval} minute${interval === 1 ? '' : 's'} ago`;

  return "just now";
};

const formatQuoteText = (text) => {
  // Convert bullet points to HTML list items
  const lines = text.split('\n');
  const formattedLines = lines.map(line => {
    if (line.startsWith('*') || line.startsWith('-')) {
      return `<li>${line.slice(1).trim()}</li>`;
    }
    return `<p>${line}</p>`;
  });
  return `<ul>${formattedLines.join('')}</ul>`;
};

function App() {
  const [quote, setQuote] = useState('');
  const [quotes, setQuotes] = useState([]);
  const [filter, setFilter] = useState('recent');
  const [replyTexts, setReplyTexts] = useState({});
  const [replyFormVisible, setReplyFormVisible] = useState({});

  const fetchQuotes = useCallback(async () => {
    let { data } = await supabase
      .from('quotes')
      .select('*')
      .is('parent_id', null) // Fetch only main quotes
      .order(filter === 'recent' ? 'created_at' : 'upvotes', { ascending: false });

    if (!data) {
      data = [];
    }

    // Fetch replies for each quote
    const quotesWithReplies = await Promise.all(data.map(async (quote) => {
      const { data: replies } = await supabase
        .from('quotes')
        .select('*')
        .eq('parent_id', quote.id)
        .order('created_at', { ascending: true });
      return { ...quote, replies: replies || [] };
    }));

    setQuotes(quotesWithReplies);
  }, [filter]);

  useEffect(() => {
    fetchQuotes();
  }, [filter, fetchQuotes]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js";
    script.async = true;
    script.setAttribute('data-name', 'bmc-button');
    script.setAttribute('data-slug', 'vixclotet');
    script.setAttribute('data-color', '#FFDD00');
    script.setAttribute('data-emoji', '☕');
    script.setAttribute('data-font', 'Cookie');
    script.setAttribute('data-text', 'Buy me a coffee');
    script.setAttribute('data-outline-color', '#000000');
    script.setAttribute('data-font-color', '#000000');
    script.setAttribute('data-coffee-color', '#ffffff');

    document.getElementById('bmc-container').appendChild(script);
  }, []);

  const addQuote = async () => {
    if (quote.trim()) {
      if (containsMeanWords(quote)) {
        alert("Your quote contains inappropriate language. Please remove it and write a positive quote.");
        return;
      }
      await supabase.from('quotes').insert([{ text: quote, upvotes: 0, created_at: new Date() }]);
      setQuote('');
      fetchQuotes();
    }
  };

  const upvoteQuote = async (id) => {
    const { data } = await supabase
      .from('quotes')
      .select('upvotes')
      .eq('id', id)
      .single();

    await supabase
      .from('quotes')
      .update({ upvotes: data.upvotes + 1 })
      .eq('id', id);

    fetchQuotes();
  };

  const upvoteReply = async (id) => {
    const { data } = await supabase
      .from('quotes')
      .select('upvotes')
      .eq('id', id)
      .single();

    await supabase
      .from('quotes')
      .update({ upvotes: data.upvotes + 1 })
      .eq('id', id);

    fetchQuotes();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Quote copied to clipboard! 📋');
  };

  const toggleReplyForm = (quoteId) => {
    setReplyFormVisible((prev) => ({ ...prev, [quoteId]: !prev[quoteId] }));
  };

  const addReply = async (quoteId) => {
    const replyText = replyTexts[quoteId];
    if (replyText && replyText.trim()) {
      if (containsMeanWords(replyText)) {
        alert("Your reply contains inappropriate language. Please remove it and write a positive reply.");
        return;
      }
      await supabase.from('quotes').insert([{ text: replyText, upvotes: 0, created_at: new Date(), parent_id: quoteId }]);
      setReplyTexts((prev) => ({ ...prev, [quoteId]: '' })); // Clear the reply text box
      fetchQuotes();
    }
  };

  return (
    <div className="min-h-screen bg-fuchsia-50 flex flex-col justify-between">
      <Analytics />

      <header className="bg-white shadow-md p-2 fixed top-0 left-0 right-0 flex items-center justify-between flex-wrap">
        <div className="flex items-center ml-4">
          <img
            src="posquote_logo.png"
            alt="Logo"
            className="h-16 w-16 sm:h-20 sm:w-20"
          />
        </div>
        <h1 className="text-bold text-center text-2xl font-mono font-bold sm:text-2xl md:text-4xl mx-2 my-2">
          📖 PosQuotes 📖
        </h1>
        <div className="flex items-center space-x-2 sm:space-x-4 mr-4">
          <a
            href="https://x.com/vixclotet"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-cyan-700 text-white p-4 rounded-xl sm:rounded-2xl text-sm sm:text-sm md:text-base"
          >
            Contact 📲
          </a>

          <a
            href="https://www.buymeacoffee.com/vixclotet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Buy Me A Coffee"
              style={{ height: '60px', width: '217px' }}
            />
          </a>

          <div id="bmc-container" className="flex flex-col"></div>
        </div>
      </header>

      <main className="flex-grow p-4 mt-24 flex flex-col items-center">
        <div className="w-full md:w-2/3 bg-amber-50 p-4 mt-8 md:mt-0 md:p-6 rounded-lg shadow">
          <h2 className="text-4xl font-mono font-bold mb-4 text-center">Welcome to PosQuotes!</h2>
          <h2 className="text-lg font-bold mb-4 text-center md:text-left">How to Use PosQuotes 📜</h2>
          <p className="text-gray-700 mb-3">
            1. <b>Write</b> your daily mood and thoughts in the text box.
          </p>
          <p className="text-gray-700 mb-3">
            2. Click <b>"Add Quote"</b> to add your quote to the PosQuotes Wall.
          </p>
          <p className="text-gray-700 mb-3">
            3. <b>Use the buttons</b> to view the most recent or most upvoted quotes.
          </p>
          <p className="text-gray-700">
            4. <b>Share quotes</b> on social media or <b>upvote</b> your favorites!
          </p>
        </div>

        <div className="w-full md:w-2/3 md:pr-8">
          <div className="flex flex-col items-center mt-12 mb-4">
            <textarea
              className="border p-2 rounded w-full sm:w-11/12"
              placeholder="Write your daily mood and thoughts here..."
              value={quote}
              onChange={(e) => setQuote(e.target.value)}
            />
            <button
              onClick={addQuote}
              className="bg-cyan-700 text-white mt-2 p-2 rounded-xl sm:rounded-2xl"
            >
              Add Quote
            </button>
          </div>
          <div className="flex justify-center mb-4">
            <button
              onClick={() => setFilter('recent')}
              className={`p-2 rounded-l ${filter === 'recent' ? 'bg-cyan-700 text-white' : 'bg-gray-300'}`}
            >
              Recent
            </button>
            <button
              onClick={() => setFilter('popular')}
              className={`p-2 rounded-r ${filter === 'popular' ? 'bg-cyan-700 text-white' : 'bg-gray-300'}`}
            >
              Most Upvoted
            </button>
          </div>

          <div className="w-full">
            {quotes.length > 0 ? (
              quotes.map((q) => (
                <div key={q.id} className="bg-white p-4 rounded-lg shadow-sm mb-4 w-full" style={{ boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.1)' }}>
                  <div dangerouslySetInnerHTML={{ __html: formatQuoteText(q.text) }} /> {/* Render formatted text */}
                  <small className="text-gray-500">
                    {timeAgo(q.created_at)} {/* Show relative time here */}
                  </small>
                  <div className="flex justify-between items-center mt-2">
                    <button
                      onClick={() => upvoteQuote(q.id)}
                      className="flex items-center text-cyan-700"
                    >
                      <FiThumbsUp className="mr-1" /> {q.upvotes}
                    </button>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => copyToClipboard(q.text)}
                        className="flex items-center text-gray-500"
                      >
                        <FiCopy className="mr-1" /> Copy
                      </button>
                      <a
                        href={`https://www.instagram.com/share?text=${encodeURIComponent(q.text)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram className="text-pink-600" />
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(q.text)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter className="text-blue-500" />
                      </a>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(q.text)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin className="text-blue-700" />
                      </a>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(q.text)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook className="text-blue-600" />
                      </a>
                    </div>
                  </div>
                  {/* Display replies */}
                  <div className="ml-4 mt-4">
                    {q.replies.map((reply) => (
                      <div key={reply.id} className="bg-gray-100 p-2 rounded-lg mb-2">
                        <div dangerouslySetInnerHTML={{ __html: formatQuoteText(reply.text) }} /> {/* Render formatted reply */}
                        <small className="text-gray-500">{timeAgo(reply.created_at)}</small>
                        <div className="flex justify-between items-center mt-2">
                          <button
                            onClick={() => upvoteReply(reply.id)}
                            className="flex items-center text-cyan-700"
                          >
                            <FiThumbsUp className="mr-1" /> {reply.upvotes}
                          </button>
                        </div>
                      </div>
                    ))}
                    {/* Add reply button */}
                    <button
                      onClick={() => toggleReplyForm(q.id)}
                      className="bg-cyan-700 text-white mt-2 p-2 rounded-xl sm:rounded-2xl"
                    >
                      {replyFormVisible[q.id] ? 'Cancel Reply' : 'Add Reply'}
                    </button>
                    {/* Add reply form */}
                    {replyFormVisible[q.id] && (
                      <div>
                        <textarea
                          className="border p-2 rounded-lg w-full mt-2"
                          placeholder="Write your reply here..."
                          value={replyTexts[q.id] || ''} // Bind the textarea to the individual reply text
                          onChange={(e) => setReplyTexts((prev) => ({ ...prev, [q.id]: e.target.value }))}
                        />
                        <button
                          onClick={() => addReply(q.id)}
                          className="bg-cyan-700 text-white mt-2 p-2 rounded-xl sm:rounded-2xl"
                        >
                          Submit Reply
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No quotes available.</p>
            )}
          </div>
        </div>
      </main>

      <footer className="bg-white p-4 text-center text-xs sm:text-sm md:text-base">
        <p>2024 Copyright © Adeu, LLC. All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default App;